html,
* {
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  color: #ffffff;
  scroll-behavior: smooth;
}
html {
  background-color: #232323;
}

.safeAreaView {
  width: 1080px;
}

.container {
  display: flex;
  justify-content: center;
  padding: 0px 48px;
}

a {
  text-decoration: none;
}

body::-webkit-scrollbar {
  display: none;
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-x: hidden;
}

@media (max-width: 1023px) {
  .safeAreaView {
    width: 100%;
  }
  .container {
    padding: 0px 24px;
  }
  html {
    font-size: 10px;
  }
}
